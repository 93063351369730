// This is the scss entry file
import * as Sentry from "@sentry/browser";
import $ from "jquery";
import "bootstrap-sass/assets/javascripts/bootstrap/affix";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown";
import "../styles/index.scss";
import {csrfToken} from "../components/csrf_token";
import "../styles/defaults-standard.less";

csrfToken.initialize();

window.$ = $;

window.app = {
    initialize: function (landingPageSearchQuery, showSelectedHotels, removeStoredHotelURL, requestSelectURL, requestCreateURL, sentryDSN) {
        if (sentryDSN) {
            Sentry.init({
                dsn: sentryDSN,

                // Alternatively, use `process.env.npm_package_version` for a dynamic release version
                // if your build tool supports it.
                //release: "my-project-name@2.3.12",
                //integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,

                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

                // Capture Replay for 10% of all sessions,
                // plus for 100% of sessions with an error
                //replaysSessionSampleRate: 0.1,
                //replaysOnErrorSampleRate: 1.0,
                ignoreErrors: [
                    // bingbot/2.0
                    'Event `Event` (type=error) captured as promise rejection',
                    // Lingdys Chrome extension
                    /Failed to execute 'define' on 'CustomElementRegistry': the name "lingdys/
                ]
            });
        }
        // Activate all Bootstrap dropdown
        $('.dropdown-toggle').dropdown();

        // Mobile-specific adjustments
        if ($('#mobile-detector').is(':hidden')) {
            $('footer').removeClass('fixed-footer').addClass('footer');
        }
        // Handle click on create request link
        $('#request-create-link').click(function () {
            let params = location.search;
            if (landingPageSearchQuery) {
                if (params.length > 0) {
                    if (params.indexOf('q=') === -1) {
                        params += '&q=' + landingPageSearchQuery;
                    }
                } else {
                    params += '?q=' + landingPageSearchQuery;
                }
            }
            window.location.href = $(this).attr('href') + params;
            return false;
        });

        // Open all external links in new tab
        $('a').attr('target', function () {
            if (this.host !== location.host) {
                return '_blank';
            }
        });

        let that = this;
        if (showSelectedHotels) {
            this.positionSelectedHotelsPanel();
            $(window).resize(function () {
                that.positionSelectedHotelsPanel();
            });

            // Handle remove hotel buttons
            $(document).on('click', 'button[id^=selected-hotels-remove-]', function () {
                that.updateStoredHotel($(this).attr('id').split('-')[3], removeStoredHotelURL, requestSelectURL, requestCreateURL);
                return false;
            });
            $(document).on('click', 'button[id^=selected-hotels-mobile-remove-]', function () {
                that.updateStoredHotel($(this).attr('id').split('-')[4], removeStoredHotelURL, requestSelectURL, requestCreateURL);
                return false;
            });
        }

        // Multilevel Bootstrap 3 dropdown menus
        $(".dropdown-menu > li > a.trigger").on("click", function (e) {
            let current = $(this).next();
            let grandparent = $(this).parent().parent();
            if ($(this).hasClass('left-caret') || $(this).hasClass('right-caret'))
                $(this).toggleClass('right-caret left-caret');
            grandparent.find('.left-caret').not(this).toggleClass('right-caret left-caret');
            grandparent.find(".sub-menu:visible").not(current).hide();
            current.toggle();
            e.stopPropagation();
        });
        $(".dropdown-menu > li > a:not(.trigger)").on("click", function () {
            let root = $(this).closest('.dropdown');
            root.find('.left-caret').toggleClass('right-caret left-caret');
            root.find('.sub-menu:visible').hide();
        });
    },

    positionSelectedHotelsPanel: function () {
        // Position selected hotels panel
        let selectedHotelsPanelElem = $('#selected-hotels-panel');
        let selectedHotelsMobilePanelElem = $('#selected-hotels-mobile-panel');
        if (window.innerWidth < 1700) {
            selectedHotelsPanelElem.css('display', 'none');
            selectedHotelsMobilePanelElem.css('display', 'block');
        } else {
            selectedHotelsPanelElem.css('display', 'block');
            selectedHotelsMobilePanelElem.css('display', 'none');
        }
        let mainContainerElem = $('#main-container');
        let mainContainerPos = mainContainerElem.offset();
        mainContainerPos.left += mainContainerElem.outerWidth() + 20;
        mainContainerPos.top += 77;
        mainContainerPos.position = 'fixed';
        selectedHotelsPanelElem.css(mainContainerPos);
        selectedHotelsPanelElem.affix({
            offset: mainContainerPos.top
        });
    },

    updateStoredHotel: function (hotelId, removeStoredHotelURL, requestSelectURL, requestCreateURL) {
        $.post(removeStoredHotelURL, {hotel_id: hotelId}, function (data) {
            // Update number of hotels

            $('#num-hotels').html(data.num_stored_hotels);

            // Show choose button again
            $('button[id="choose-hotel-' + hotelId + '"]').removeClass('hidden');

            // Hide removed hotel
            $('#selected-hotel-' + hotelId).css('display', 'none');
            $('#selected-mobile-hotel-' + hotelId).css('display', 'none');

            // Make navigation pills inactive if necessary

            if (data.num_stored_hotels === 0) {
                $('#selected-hotels-panel').addClass('hidden');
                $('#selected-hotels-mobile-panel').addClass('hidden');
                let elemIds = [`a[href^="${requestSelectURL}"]`, `a[href^="${requestCreateURL}"]`];
                $.each(elemIds, function (index, elemId) {
                    let elem = $(elemId);
                    if (!elem.hasClass('disabled')) {
                        elem.addClass('disabled');
                    }
                });
            }
        });
    }


};


window.carouselNormalization = function (items) {
    let heights = [],                                   // array to store heights
        tallest;                                        // tallest slide

    function normalizeHeights() {
        items.each(function () {
            heights.push($(this).height());               // add each slide's height
        });                                             // to the array
        console.log(heights);
        tallest = Math.max.apply(null, heights);        // find the largest height

        items.each(function () {
            $(this).css('min-height', tallest + 'px');    // set each slide's minimum
        });                                             // height to the largest
    }

    if (items.length) {

        normalizeHeights();

        $(window).on('resize orientationchange', function () {
            tallest = 0, heights.length = 0;               // reset the variables

            items.each(function () {
                $(this).css('min-height', '0');              // reset each slide's height
            });

            normalizeHeights();                            // run it again
        });
    }
};


window.imageSizeCheck = function (elem) {
    let file, img;
    let _URL = window.URL || window.webkitURL;
    if ((file = elem.files[0])) {
        img = new Image();
        let objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
            let doReset = false;
            if (this.width > 10000 || this.height > 8000) {
                alert("Bildet kan maksimalt ha 10000 x 8000 piksler.");
                doReset = true;
            } else if (this.width < 555 || this.height < 310) {
                alert("Bildet kan ikke være mindre enn 555 x 310 piksler.");
                doReset = true;
            } else if ((this.width / this.height) > 2) {
                alert("Forholdet mellom bredde og vidde kan ikke overstige 2:1.");
                doReset = true;
            }
            if (doReset) {
                $(elem).val("");
                $(elem).next().find("input").val("");
            }
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    }
};

/*
window.addEventListener("load", function () {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#edeff5",
                "text": "#838391"
            },
            "button": {
                "background": "#00909e"
            }
        },
        "content": {
            "message": "Denne websiden bruker cookies for å gi deg den beste brukeropplevelsen.",
            "dismiss": "Aksepter",
            "link": "Lær mer",
            "href": "/s/personvernerklaring"
        }
    });
});
 */
